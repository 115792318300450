<template>
  <layout
    v-if="activeModelUpdated"
    :activeIndex="activeIndex"
    :activeModel="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <template #left-0>
      <left-menu :list="list" @select="leftMenuSelect"></left-menu>
    </template>

    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>
    <template #left-2></template>
    <template #right-0>
      <div class="position-absolute" style="right: 1rem; width: 30rem">
        <b-card
          v-for="(position, positionIndex) in activeModelLocations"
          v-if="activeModelUpdated"
          :key="'lvl_1_' + positionIndex"
          class="mb-2"
        >
          <h4 class="mb-1">
            {{ list[activeFloorIndex].title + " " + getPosition(position) }}
          </h4>
          <b-row no-gutters>
            <b-col
              v-for="(item, key) in lightPositionFilter(position)"
              :key="'lvl_2+' + key"
              cols="6"
            >
              <b-form-checkbox
                :checked="item.light_status"
                :disabled="!item.equip_key"
                style="z-index: 2 !important"
                switch
                @change="lightSwitch(key, item)"
              >
                {{ lightNameFormat(item.equip_name) }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          striped
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
          <template v-slot:cell(params)="data">
            <template v-for="(param, param_index) in data.item.params">
              <span
                v-if="param.param_name"
                :style="param.cur_value === null ? 'opacity:0.3' : ''"
              >
                <span class="mx-50" v-if="param_index !== 0">,</span>
                <span>{{
                  param.param_name.replace(data.item.equip_name + "-", "")
                }}</span
                >:
                <span v-if="param.cur_value === null">N/A</span>
                <span v-else>{{
                  param.unit && param.unit.indexOf("||") > -1
                    ? param.unit.split("||")[param.cur_value > 0 ? 1 : 0]
                    : param.cur_value + " " + (param.unit || "")
                }}</span>
              </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm"
                >查看
              </b-button>
              <b-button
                variant="success"
                size="sm"
                @click="equip_to_detail_model(data.item)"
                :disabled="!data.item.detail_model_key"
                >详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="items.length"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      ></b-pagination>
    </template>
    <template #right-2>
      <panel-group :show-title="false" group-uid="b7e362df"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";
import { computed, reactive, ref, toRefs, watch } from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { showToast } from "@/libs/utils/showToast";
import vSelect from "vue-select";

export default {
  name: "intelligent-lighting",
  components: {
    vSelect,
    Layout,
    LeftMenu,
    AppCollapse,
    AppCollapseItem,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup(props, ctx) {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModelUpdated: true,
      activeFloorIndex: null,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "light_system/light_system/light_system",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        { title: "设备列表" },
        /* { title: "模式管理" }, */
        { title: "数据分析" },
      ],
    });

    //左侧菜单相关参数
    const leftMenuParams = reactive({
      list: [
        {
          title: "F12",
          value: 0,
          model: {
            path: "light_system/light_system_F11/light_system_F11",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F11",
          value: 1,
          model: {
            path: "light_system/light_system_F10/light_system_F10",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F10",
          value: 2,
          model: {
            path: "light_system/light_system_F9/light_system_F9",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F9",
          value: 3,
          model: {
            path: "light_system/light_system_F8/light_system_F8",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F8",
          value: 4,
          model: {
            path: "light_system/light_system_F7/light_system_F7",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F7",
          value: 5,
          model: {
            path: "light_system/light_system_F6/light_system_F6",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "light_system/light_system_F5/light_system_F5",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F5",
          value: 7,
          model: {
            path: "light_system/light_system_F4/light_system_F4",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F3",
          value: 8,
          model: {
            path: "light_system/light_system_F3/light_system_F3",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F2",
          value: 9,
          model: {
            path: "light_system/light_system_F2/light_system_F2",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "F1",
          value: 10,
          model: {
            path: "light_system/light_system_F1/light_system_F1",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "B1",
          value: 11,
          model: {
            path: "light_system/light_system_B1/light_system_B1",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "B2",
          value: 12,
          model: {
            path: "light_system/light_system_B2/light_system_B2",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "B3",
          value: 13,
          model: {
            path: "light_system/light_system_B3/light_system_B3",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        {
          title: "B5",
          value: 14,
          model: {
            path: "light_system/light_system_B4/light_system_B4",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
      ],
    });

    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      layoutParams.activeFloorIndex = event.value;
      layoutParams.activeModel = leftMenuParams.list[event.value].model;
      store.commit("jsf35/setActiveModel", event.model);
    };

    const activeModelLocations = computed(() => {
      let locations = {};
      let light_switch = layoutParams.activeModel?.light_switch;
      if (light_switch) {
        console.log("keys", Object.keys(light_switch));
        Object.keys(light_switch).map((equip_meter_code) => {
          locations[equip_meter_code.split("_")[3]] = 0;
        });
      }
      return Object.keys(locations);
    });

    watch(
      () => store.state.jsf35.equip_list,
      (equip_list) => {
        equip_list = JSON.parse(JSON.stringify(equip_list));
        // equip_list.map((item) => {
        //   if(item.equip_key){
        //     item["light_status"] = item.params ? item.params[0].cur_value === -1 : false;
        //     item["light_list"] = {};
        //     item.equip_key.split(",").map((light_key) => {
        //       if (light_key) {
        //         item["light_list"]["equipment_" + light_key] = item["light_status"];
        //       }
        //     });
        //   }
        //   //整体模型
        //   layoutParams.topBarItems[0]["model"]["light_switch"][item.equip_meter_code] = item
        //   //各楼层模型
        //   leftMenuParams.list.map((floor) => {
        //     floor["model"]["light_switch"][item.equip_meter_code] = item;
        //   })
        //
        // });

        leftMenuParams.list.map((floor) => {
          equip_list.map((item) => {
            item["light_status"] =
              item.params && item.params[0]
                ? item.params[0].cur_value === -1
                : false;
            item["light_list"] = {};
            item.equip_key &&
              item.equip_key.split(",").map((light_key) => {
                if (light_key) {
                  item["light_list"]["equipment_" + light_key] =
                    item["light_status"];
                }
              });
            if (item.floor === floor.title) {
              floor["model"]["light_switch"][item.equip_meter_code] = item;
            }
            layoutParams.topBarItems[0]["model"]["light_switch"][
              item.equip_meter_code
            ] = item;
          });
          if (floor.value === layoutParams.activeFloorIndex) {
            layoutParams.activeModel.light_switch = Object.assign(
              {},
              floor.model.light_switch
            );
          }
        });
      }
    );

    const getPosition = (key) => {
      let text = "中央大厅";
      if (key === "S") {
        text = "南楼";
      } else if (key === "N") {
        text = "北楼";
      } else if (key === "W") {
        text = "西走廊";
      }
      return text;
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    //灯光开关
    const lightSwitch = (key, item) => {
      let status = item.light_status;
      layoutParams.activeModelUpdated = false;
      let light_switch = layoutParams.activeModel.light_switch;
      light_switch[key].light_status = !status;
      layoutParams.activeModel.light_switch = Object.assign({}, light_switch);
      console.log("light_switch", light_switch[key].light_list);
      layoutParams.activeModelUpdated = true;
      //若存在灯光控制参数，则实现灯光控制。-1开启，0关闭
      if (item.params && item.params[0]) {
        let command_list = [
          {
            tag_name: item.params[0].tag_name,
            cur_value: status ? 0 : -1,
          },
        ];
        submit_command(command_list);
      } else {
        showToast(null, "提示", "此点位未配置控制信号");
      }
    };

    //发送请求修改控制参数
    const submit_command = (list) => {
      store
        .dispatch("jsf35/submitCommand", { project_id: 72, list: list })
        .then((response) => {
          showToast(null, "成功", "指令已下发成功！");
        })
        .catch((error) => {
          showToast(error);
        });
    };

    //灯光按照位置筛选
    const lightPositionFilter = (position) => {
      let lights = {};
      for (let key in layoutParams.activeModel.light_switch) {
        if (key.split("_")[3] === position) {
          //去除【全开关】和【感应器屏蔽】的回路
          if (
            !(
              key.includes("_Indicator_") ||
              key.includes("_All_") ||
              key.includes("_100_")
            )
          ) {
            lights[key] = layoutParams.activeModel.light_switch[key];
          }
        }
      }
      console.log("lights", lights);
      return lights;
    };

    const data = reactive({
      index: 0,
      selected_type: 0,
      options_type: [
        { value: 0, text: "全部" },
        { value: 1, text: "普通" },
        { value: 2, text: "应急" },
      ],
      selected_area: 0,
      options: [
        { value: 0, text: "全部" },
        { value: 1, text: "南楼" },
        { value: 2, text: "北楼" },
        { value: 3, text: "地下" },
      ],
      selected_floors: 0,
      options_floors: [
        { value: 0, text: "全部" },
        { value: 1, text: "F12" },
        { value: 2, text: "F11" },
        { value: 3, text: "F10" },
        { value: 4, text: "F9" },
        { value: 5, text: "F8" },
        { value: 6, text: "F7" },
        { value: 7, text: "F6" },
        { value: 8, text: "F5" },
        { value: 9, text: "F4" },
        { value: 10, text: "F3" },
        { value: 11, text: "F2" },
        { value: 12, text: "F1" },
      ],
      fields: [
        { label: "ID", key: "equip_id" },
        { label: "设备名称", key: "equip_name" },
        { label: "设备编号", key: "equip_meter_code" },
        { label: "楼层", key: "floor" },
        { label: "参数状态", key: "params" },
        { label: "操作", key: "action" },
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,
    });

    watch(
      () => store.state.jsf35.equip_list,
      (list) => {
        data.items = list;
        data.rows = list.length;
      }
    );

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };

    const lightNameFormat = (lightName) => {
      let replace_str_arr = [
        "南楼",
        "北楼",
        "西走廊",
        "层",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
      ];
      replace_str_arr.map((str) => {
        lightName = lightName.replace(str, "");
      });
      return lightName;
    };

    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "RF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      data.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      data.rows = data.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      getPosition,
      lightNameFormat,
      lightSwitch,
      activeModelLocations,
      lightPositionFilter,
      equip_to_detail_model,
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped></style>
